
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "Unauthorized",
  methods: {
    ...mapActions({
      logout: "LOGOUT",
    }),
    toHome(): void {
      this.$router.push({ name: "home" });
    },
  },
  mounted(): void {
    this.$nextTick(function () {
      if (!this.user.userRoles.length) {
        confirm(
          "Identificamos que nenhuma regra foi definida para seu usuário. Entre em contato com a Agrinvest e faça o acesso novamente."
        );
        this.logout();
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "getLoggedUser",
    }),
  },
});
